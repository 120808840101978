<template>
  <b-modal
    v-if="affiliate"
    id="affiliate-delete"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    no-stacking
    ok-title="Delete Affiliate"
    ok-variant="danger"
    modal-class="data-entry"
    title="Are you sure you want to delete this affiliate?"
    @ok="deleteAffiliate"
  >
    You are about to delete {{ affiliate.name }}.
    <span v-if="affiliate.can_be_hard_deleted">
      The affiliate profile and all related data will be deleted permanently.
    </span>
    <span v-else>
      The affiliate profile will be deleted (inactive).
    </span>

    <div class="row input-row mt-4">
      <div class="col-12 col-lg-6">
        <ifac-dropdown
          title="Reason"
          v-model="form.reason"
          :list="deleteReasons"
          :required="true"
        />
      </div>
    </div>

    <div class="row input-row" v-if="form.reason === 'other'">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.message"
          name="message"
          title="Other reason"
          rules="max:255"
          placeholder="Write the reason..."
          :required="form.reason === 'other'"
        ></ifac-valid-input>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Affiliates from '@/services/Api/Affiliates';
import {
  IfacValidInput,
  IfacDropdown,
} from '@ifac/ui';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    IfacValidInput,
    IfacDropdown,
  },
  props: ['affiliate'],
  data() {
    return {
      form: {
        reason: '',
        message: '',
      },
    };
  },
  methods: {
    ...mapMutations({
      setRequestError: 'formHelpers/setError',
    }),
    async deleteAffiliate(okEvent) {
      okEvent.preventDefault();

      try {
        await Affiliates.delete(this.affiliate.id, this.form);
        this.$snack.success({
          text: 'Affiliate deleted.',
        });

        this.$router.push({ name: 'Affiliates' });

        this.$nextTick(() => {
          this.$bvModal.hide('affiliate-delete');
        });
      } catch (error) {
        if (error.response?.status === 422 || error.response?.status === 403) {
          this.$snack.danger({
            text: error.response.data.error.message,
          });
          this.setRequestError(error.response.data.error);
        } else {
          this.$snack.danger({
            text: 'There was an error deleting the affiliate, please try again.',
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      deleteReasons: 'deleteReasons/data',
    }),
    formIsValid() {
      return !this.$v.$invalid;
    },
  },
};
</script>
