<template>
  <div>
    <b-container>
      <tabs
        :tabItems="TAB_ITEMS"
        defaultTab="general-details">
        <template slot="tab-content">
          <component
            :is="activeTabComponent"
            v-if="affiliate"
            :affiliate="affiliate"
            :contact-info="affiliate.contactInfo"
            :addresses="affiliate.addresses">
            <router-link :to="editSectionUrl" class="btn btn-primary float-right">Edit</router-link>
            <b-button v-if="canDeleteAffiliate"
                      variant="danger"
                      class="float-right mr-4"
                      v-b-modal.affiliate-delete>Delete</b-button>
          </component>
          <router-view />
        </template>
      </tabs>
      <DeleteModal :affiliate="affiliate"></DeleteModal>
    </b-container>
  </div>
</template>

<script>
import Affiliates from '@/services/Api/Affiliates';
import { IfacLoader, AdminAffiliateProfileTabs } from '@ifac/ui';
import Tabs from '@/views/components/Tabs.vue';
import GeneralDetails from '@/views/areas/affiliates/view-partials/GeneralDetails.vue';
import ContactDetails from '@/views/areas/affiliates/view-partials/ContactDetails.vue';
import Addresses from '@/views/areas/affiliates/view-partials/Addresses.vue';
import Roles from '@/views/areas/affiliates/view-partials/Roles.vue';
import Notes from '@/views/areas/affiliates/view-partials/Notes.vue';
import LoginInfo from '@/views/areas/affiliates/view-partials/LoginInfo.vue';
import DeleteModal from '@/views/areas/affiliates/view-partials/DeleteModal.vue';

export default {
  components: {
    Tabs,
    GeneralDetails,
    ContactDetails,
    Addresses,
    Roles,
    Notes,
    LoginInfo,
    IfacLoader,
    DeleteModal,
  },
  data() {
    return {
      busy: false,
      affiliate: null,
    };
  },
  created() {
    this.TAB_ITEMS = AdminAffiliateProfileTabs;
    this.fetchAffiliate();
  },
  computed: {
    activeTabComponent() {
      return this.$route.query.section ?? this.TAB_ITEMS[0]?.name;
    },
    editSectionUrl() {
      return {
        name: 'AffiliatesEdit',
        query: { section: this.activeTabComponent },
        params: { id: this.affiliate.id },
      };
    },
    canDeleteAffiliate() {
      return !this.affiliate.is_trashed && this.affiliate.status === 'rejected';
    },
  },
  methods: {
    async fetchAffiliate() {
      this.busy = true;
      const {
        data: { data },
      } = await Affiliates.show(this.$route.params.id);

      this.affiliate = data;
      this.busy = false;
    },
  },
};
</script>
