<template>
  <div class="card p-md-3">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center">
          <div class="col-md-9">
            <h3 class="font-weight-bold d-block mb-0">Roles In IFAC</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>
        <div class="row">
          <div class="col mt-3">
            <p>
              <a :href="mx_certificateLink(affiliate, 'ifac')"
                 v-b-tooltip.hover title="Download certificate">
                <font-awesome-icon class="mr-1"
                                   role="button"
                                   :icon="['fal', 'download']"/>
              </a>
              IFAC Affiliate
            </p>
            <p v-for="(formattedPosition, key) in mx_sortedAffiliatePositions" :key="key">
              <a :href="mx_certificateLink(affiliate, 'role', formattedPosition.position)"
                 v-b-tooltip.hover title="Download certificate">
                <font-awesome-icon class="mr-1"
                                   role="button"
                                   :icon="['fal', 'download']"/>
              </a>
              {{ mx_getPositionName(formattedPosition) }}
              <span class="d-block alert alert-light p-2 border" v-if="formattedPosition.notes">
                <IfacRoleNotes :text="formattedPosition.notes"/>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ifacPositionsMixin, IfacRoleNotes } from '@ifac/ui';

export default {
  components: {
    IfacRoleNotes,
  },
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
  mixins: [ifacPositionsMixin],
  computed: {
    currentPositions() {
      return this.affiliate?.positions;
    },
  },
};
</script>
