<template>
  <div class="card p-md-3">
    <div class="card-body ">
      <div class="general-details">
        <div class="row align-items-center">
          <div class="col-md-9">
            <h3 class="font-weight-bold d-block mb-0">Profile</h3>
            <p class="text-black-50 small mb-0">
              <span v-if="affiliate.last_login_at">
              Last Logged: {{ affiliate.last_login_at }} ({{ affiliate.last_login_at_for_humans }})
              </span>
              <span v-else>
              Last Logged: {{ affiliate.last_login_at_for_humans }}
              </span>
            </p>
          </div>
          <div class="col">
            <slot></slot>
          </div>
        </div>
        <!--  -->
        <div>
          <div class="row align-items-start mt-5">
            <div class="col-auto">
              <img
                v-if="affiliate.avatar"
                :src="affiliate.avatar"
                :alt="nameNoTitle"
                width="100"
                height="100"
                class="rounded-lg"
              />
              <div
                v-else
                style="width: 100px; height: 100px"
                class="
                  rounded-lg
                  bg-light
                  d-flex
                  justify-content-center
                  align-items-center
                  text-primary
                  font-weight-bold
                "
              >
                <div>
                  {{ nameNoTitle | acronym }}
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div>
                <p class="font-weight-bold">Title</p>
                <p class="">{{ affiliate.title || "-" }}</p>
              </div>
            </div>
            <div class="col-auto">
              <div>
                <p class="font-weight-bold">Name</p>
                <p class="">{{ affiliate.name || "-" }} {{ affiliate.is_deceased ? '✝' : '' }}</p>
              </div>
            </div>
            <div class="col">
              <div>
                <p class="font-weight-bold">Surname</p>
                <p class="">{{ affiliate.surname || "-" }}</p>
              </div>
            </div>
            <div class="col">
              <div>
                <p class="font-weight-bold">Gender</p>
                <p class="">{{ affiliate.gender || "-" }}</p>
              </div>
            </div>
          </div>

          <hr class="mb-4" style="border-style: dashed" />

          <div class="row mb-4">
            <div class="col-md-4">
              <p class="font-weight-bold">Affiliation</p>
              <p class="">{{ affiliate.affiliation || "-" }}</p>
            </div>
            <div class="col">
              <p class="font-weight-bold">Academia / Government / Industry</p>
              <p class="">{{ affiliate.industryAcademiaGovernment || "-" }}</p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-4">
              <p class="font-weight-bold">Position</p>
              <p class="">{{ affiliate.jobPosition || "-" }}</p>
            </div>
            <div class="col">
              <p class="font-weight-bold">Job Description</p>
              <p class="">{{ affiliate.jobDescription || "-" }}</p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-4">
              <p class="font-weight-bold">Career Stage</p>
              <p class="">{{ affiliate.careerStage || "-" }}</p>
            </div>
            <div class="col-md-4">
              <p class="font-weight-bold">Country / Region</p>
              <p v-if="affiliate.country" class="">{{ affiliate.country.name }}</p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-4">
              <p class="font-weight-bold">Disability</p>
              <p class="">{{ affiliate.disability || "-" }}</p>
            </div>
          </div>
          <hr class="mb-4" style="border-style: dashed" />

          <div class="row mb-4">
            <div class="col-12 col-md-4">
              <p class="font-weight-bold">General information</p>
              <p class="">{{ affiliate.notesForSecretariat || "-" }}</p>
            </div>
          </div>

          <hr class="mb-4" style="border-style: dashed" />
          <div class="row mb-4">
            <div class="col">
              <p class="font-weight-bold">Fields of Interest</p>
              <p v-if="hasFieldsOfInterest">
                <span v-for="(fi, key) in fieldsOfInterest" :key="key"
                  ><span :title="fi.technicalCommittee.name">{{ fi.name }}</span>
                  <span v-if="key < affiliate.fieldsOfInterest.length - 1">
                    ·
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IfacAffiliatesHelpers } from '@ifac/ui';

export default {
  props: ['affiliate'],
  computed: {
    fullName() {
      return `${this.affiliate.title} ${this.affiliate.name} ${this.affiliate.surname}`;
    },
    nameNoTitle() {
      return `${this.affiliate.name} ${this.affiliate.surname}`;
    },
    hasFieldsOfInterest() {
      return !!this.affiliate?.fieldsOfInterest?.length;
    },
    fieldsOfInterest() {
      return IfacAffiliatesHelpers.sortFoiKeywords(this.affiliate?.fieldsOfInterest);
    },
  },
};
</script>
