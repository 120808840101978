<template>
  <div class="card p-md-3">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center">
          <div class="col-md-9">
            <h3 class="font-weight-bold d-block mb-0">Login Info</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <div>
              <p class="font-weight-bold">Current Email Address</p>
              <p class="">{{ affiliate.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
};
</script>
