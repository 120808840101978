<template>
  <div class="card p-md-3">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center">
          <div class="col-md-9">
            <h3 class="font-weight-bold d-block mb-0">Notes</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>
        <div class="row my-4 text-center" v-if="!notes">
          <div class="col">
            <div class="mb-4">
              <i class="far fa-exclamation fa-4x text-primary"></i>
            </div>
            <p>No notes</p>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col mt-3">
            <p>{{ notes }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notes() {
      return this.affiliate?.notes;
    },
  },
};
</script>
