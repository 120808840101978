<template>
  <div class="card p-md-3">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center">
          <div class="col-md-9">
            <h3 class="font-weight-bold d-block mb-0">Addresses</h3>
          </div>
          <div class="col">
            <slot></slot>
          </div>
        </div>
        <!--  -->
        <div>
          <div class="row my-4 text-center" v-if="addresses.length === 0">
            <div class="col">
              <div class="mb-4">
                <i class="far fa-exclamation fa-4x text-primary"></i>
              </div>
              <p>No addresses stored</p>
            </div>
          </div>
          <div class="row row-flex" v-else>
            <div class="col-12 col-md-6 mt-3" v-for="ci in addresses" :key="ci.id">
              <div class="card h-100 border-0 shadow-none bg-light">
                <div class="card-body">
                  <span class="type-badge badge badge-pill badge-primary p-2"
                    ><p class="font-weight-bold text-uppercase mb-0">
                      {{ ci.type }}
                    </p></span
                  >
                  <div class="row">
                    <div class="col">
                      <h5 class="font-weight-bold text-primary text-uppercase">
                        {{ ci.format }}
                      </h5>
                    </div>
                  </div>
                  <p v-if="ci.line1" class="text-dark mb-0">{{ ci.line1 }}</p>
                  <p v-if="ci.line2" class="text-dark mb-0">{{ ci.line2 }}</p>
                  <p v-if="ci.line3" class="text-dark mb-0">{{ ci.line3 }}</p>
                  <p v-if="ci.town" class="text-dark mb-0">{{ ci.town }}</p>
                  <p v-if="ci.state" class="text-dark mb-0">{{ ci.state }}</p>
                  <p v-if="ci.postcode" class="text-dark mb-0">{{ ci.postcode }}</p>
                  <p v-if="ci.country" class="text-dark mb-0">{{ ci.country.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['addresses'],
  computed: {
    fullName() {
      return `${this.addresses.title} ${this.addresses.name} ${this.addresses.surname}`;
    },
  },
};
</script>
